import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {useQuery} from '@apollo/client';
import moment from 'moment';
import MaterialTable from 'material-table';
import gql from 'graphql-tag';
import LoadingView from '../../components/LoadingView';
import Typography from '@material-ui/core/Typography';
import {CSVLink} from 'react-csv';

const HOME_QUERY = gql`
    query {
        Tract(orderBy: id_asc) {
            id
            name
            grossMineralAcres
            grossSurfaceAcres
            familyNMA
            familyNSA
            state
            county
            interest {
                Lease {
                    leaseName
                    currentLessee
                    status
                }
            }
        }
        # In the dashboards current implementation this query is unused
        Finance(orderBy: date_desc) {# This query is here in the event we want to render financial data in the dashboard again
            wtiPrice
            gasPrice
            oxy
            noble
            date {
                formatted
            }
        }
        Well(orderBy: id_asc) {
            id
            status
            state
            county
        }
        ROW(orderBy: id_asc) {
            id
            status
            locatedOn {
                name
                id
                familyID
                state
                county
            }
        }
    }
`;

const Home = () => {

  // This is where we get the data from the GQL query above
  const {data, loading, error} = useQuery(HOME_QUERY);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  });

  if (loading) {
    return <LoadingView/>;
  }

  if (error) {
    return error.toString();
  }

  // This function is used to clean the county names up, so we don't have duplicates in the table
  const normalizeCountyName = (name) => {
    return name
      .trim()
      .replace(/^"|"$/g, '')
      .replace(/\s+/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
      .replace(/\s+/g, '');
  };

  const fontFaceStyle = `
    @font-face {
      font-family: 'Bradley Hand ITC';
      src: url('/BradleyHandITC.ttf') format('truetype');
    }
  `;

  // Inject the @font-face style into the document head
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = fontFaceStyle;
  document.head.appendChild(styleSheet);

  // Define the inline style for the paragraph
  // Todo: Moves these stylings to PageStyles then update where they are used
  const paragraphStyle = {
    fontFamily: 'Bradley Hand ITC, sans-serif',
    fontSize: '20px',
    fontWeight: 900,
    textAlign: 'center',
  };

  const sizeW = '120px'
  const sizeH = '20px'

  const columnStyles = {
    state: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    county: {minWidth: '120px', maxWidth: '150px', height: sizeH},
    properties: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    grossMineralAcres: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    grossSurfaceAcres: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    ogls: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    rowActive: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    rowPending: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    wellActive: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    wellInProcess: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    wellShutIn: {minWidth: '90px', maxWidth: '120px', height: sizeH},
    fontSize: {fontSize: '12px', lineHeight: '20px', fontWeight: 'bold'},
    padding: {whiteSpace: 'nowrap', paddingTop: "2px", paddingLeft: "6px", paddingRight: "0px", paddingBottom: "2px"}
  };

  const combinedData = [
    ...data.Tract.map(item => ({...item, type: 'Tract'})),
    ...data.ROW.map(item => ({...item, type: 'ROW'})),
    ...data.Well.map(item => ({...item, type: 'Well'}))
  ];
  const tractData = combinedData.reduce((acc, item) => {
    let state = ''
    let county = ''
    // This massive object is here because sometimes item.state is spelled different or abbreviated
    // Also in the future if any more states are needed
    const state_key = {
      Al: "Alabama",
      Ak: "Alaska",
      Az: "Arizona",
      Ar: "Arkansas",
      Ca: "California",
      Co: "Colorado",
      Ct: "Connecticut",
      De: "Delaware",
      Fl: "Florida",
      Ga: "Georgia",
      Hi: "Hawaii",
      Id: "Idaho",
      Il: "Illinois",
      In: "Indiana",
      Ia: "Iowa",
      Ks: "Kansas",
      Ky: "Kentucky",
      La: "Louisiana",
      Me: "Maine",
      Md: "Maryland",
      Ma: "Massachusetts",
      Mi: "Michigan",
      Mn: "Minnesota",
      Ms: "Mississippi",
      Mo: "Missouri",
      Mt: "Montana",
      Ne: "Nebraska",
      Nv: "Nevada",
      Nh: "New Hampshire",
      Nj: "New Jersey",
      Nm: "New Mexico",
      Ny: "New York",
      Nc: "North Carolina",
      Nd: "North Dakota",
      Oh: "Ohio",
      Ok: "Oklahoma",
      Or: "Oregon",
      Pa: "Pennsylvania",
      Ri: "Rhode Island",
      Sc: "South Carolina",
      Sd: "South Dakota",
      Tn: "Tennessee",
      Tx: "Texas",
      Ut: "Utah",
      Vt: "Vermont",
      Va: "Virginia",
      Wa: "Washington",
      Wv: "West Virginia",
      Wi: "Wisconsin",
      Wy: "Wyoming",
      Alabama: "Alabama",
      Alaska: "Alaska",
      Arizona: "Arizona",
      Arkansas: "Arkansas",
      California: "California",
      Colorado: "Colorado",
      Connecticut: "Connecticut",
      Delaware: "Delaware",
      Florida: "Florida",
      Georgia: "Georgia",
      Hawaii: "Hawaii",
      Idaho: "Idaho",
      Illinois: "Illinois",
      Indiana: "Indiana",
      Iowa: "Iowa",
      Kansas: "Kansas",
      Kentucky: "Kentucky",
      Louisiana: "Louisiana",
      Maine: "Maine",
      Maryland: "Maryland",
      Massachusetts: "Massachusetts",
      Michigan: "Michigan",
      Minnesota: "Minnesota",
      Mississippi: "Mississippi",
      Missouri: "Missouri",
      Montana: "Montana",
      Nebraska: "Nebraska",
      Nevada: "Nevada",
      "New Hampshire": "New Hampshire",
      "New Jersey": "New Jersey",
      "New Mexico": "New Mexico",
      "New York": "New York",
      "North Carolina": "North Carolina",
      "North Dakota": "North Dakota",
      Ohio: "Ohio",
      Oklahoma: "Oklahoma",
      Oregon: "Oregon",
      Pennsylvania: "Pennsylvania",
      "Rhode Island": "Rhode Island",
      "South Carolina": "South Carolina",
      "South Dakota": "South Dakota",
      Tennessee: "Tennessee",
      Texas: "Texas",
      Utah: "Utah",
      Vermont: "Vermont",
      Virginia: "Virginia",
      Washington: "Washington",
      "West Virginia": "West Virginia",
      Wisconsin: "Wisconsin",
      Wyoming: "Wyoming"
    };
    switch (item.type) {// This switch case creates an object which populates the dashboard table with available data
      case "Tract":
        const tractStatus = item.interest[0]?.Lease
        const oglActiveList = ['CDC', 'EXT', 'PT', 'HBP']
        state = item.state;
        county = normalizeCountyName(item.county) === "Culberson/Reeves" ? "Reeves" : normalizeCountyName(item.county);
        county = county === "Santafe" ? "SantaFe" : county;
        if (!acc[state]) {
          acc[state] = {};
        }
        if (!acc[state][county]) {
          acc[state][county] = {
            grossMineralAcres: 0,
            grossSurfaceAcres: 0,
            properties: 0,
            ogls: 0,
            ogls_InProcess: 0,
            row_active: 0,
            row_pending: 0,
            well_active: 0,
            well_InProcess: 0,
            well_ShutIn: 0,
          };
        }
        acc[state][county].grossMineralAcres += parseFloat(item.grossMineralAcres);
        acc[state][county].grossSurfaceAcres += parseFloat(item.grossSurfaceAcres);
        acc[state][county].properties += 1;
        if (tractStatus !== undefined && oglActiveList.includes(tractStatus.status)) {
          acc[state][county].ogls += 1;
        }
        if (tractStatus !== undefined && !(oglActiveList.includes(tractStatus.status))) {
          acc[state][county].ogls_InProcess += 1;
        }
        break
      case "ROW":
        if (item.locatedOn[0]) {
          const rowState = item.locatedOn[0].state ? item.locatedOn[0].state : "NOT SPECIFIED";
          let rowCounty = item.locatedOn[0].county ? normalizeCountyName(item.locatedOn[0].county) : "NOT SPECIFIED";
          rowCounty = rowCounty === "Santafe" ? "SantaFe" : rowCounty;
          if (item.status === 'Active') {
            acc[rowState][rowCounty].row_active += 1
          }
          if (item.status === 'In Process' || item.status === 'In Progress') {
            acc[rowState][rowCounty].row_pending += 1
          }
        }
        break
      case "Well":
        const wellState = normalizeCountyName(item.state) ? state_key[normalizeCountyName(item.state)] : normalizeCountyName(item.state)
        const wellCounty = normalizeCountyName(item.county)
        if (item.status === 'Active') {
          if (acc[wellState]) {
            acc[wellState][wellCounty].well_active += 1
          }
        }
        if (item.status === 'In Process' || item.status === 'In Progress') {
          if (acc[wellState]) {
            acc[wellState][wellCounty].well_InProcess += 1
          }
        }
        if (item.status === 'Shut-In') {
          if (acc[wellState]) {
            acc[wellState][wellCounty].well_ShutIn += 1
          }
        }
        break
    }

    return acc;
  }, {});

  // Prepare cumulative totals
  const cumulativeTotals = Object.values(tractData).reduce(
    (acc, counties) => {
      Object.values(counties).forEach(county => {
        acc.grossMineralAcres += county.grossMineralAcres;
        acc.grossSurfaceAcres += county.grossSurfaceAcres;
        acc.ogls += county.ogls;
        acc.ogls_InProcess += county.ogls_InProcess;
        acc.properties += county.properties;
        acc.row_active += county.row_active;
        acc.row_pending += county.row_pending;
        acc.well_active += county.well_active;
        acc.well_InProcess += county.well_InProcess;
        acc.well_ShutIn += county.well_ShutIn;
      });
      return acc;
    },
    {
      grossMineralAcres: 0, grossSurfaceAcres: 0, properties: 0, ogls: 0, ogls_InProcess: 0,
      row_active: 0, row_pending: 0, well_active: 0, well_InProcess: 0, well_ShutIn: 0
    }
  );

  const tickerData = data.Finance[0];// If we ever want the ticker data back
  let exportData = [];// When we want to export to csv
  let uniqueKey = 0;

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const cumulativeData = (tractData) => {// Packages the queried data cumulatively, per label (aka category), respective to each County along with their state Totals
    return Object.entries(tractData).map(([state, counties]) => {
      const stateTotalGMA1 = Object.values(counties).reduce((sum, county) => sum + county.grossMineralAcres, 0).toFixed(2);
      const stateTotalGMA = formatNumberWithCommas(stateTotalGMA1);
      const stateTotalGSA1 = Object.values(counties).reduce((sum, county) => sum + county.grossSurfaceAcres, 0).toFixed(2);
      const stateTotalGSA = formatNumberWithCommas(stateTotalGSA1);
      const stateTotalProperties = Object.values(counties).reduce((sum, county) => sum + county.properties, 0);
      const oglsTotal = Object.values(counties).reduce((sum, county) => sum + county.ogls, 0);
      const oglsInProcessTotal = Object.values(counties).reduce((sum, county) => sum + county.ogls_InProcess, 0);
      const countyRowActiveTotals = Object.values(counties).reduce((sum, county) => sum + county.row_active, 0);
      const countyRowPending = Object.values(counties).reduce((sum, county) => sum + county.row_pending, 0);
      const countyWellActive = Object.values(counties).reduce((sum, county) => sum + county.well_active, 0);
      const countyWellInProcess = Object.values(counties).reduce((sum, county) => sum + county.well_InProcess, 0);
      const countyWellShutIn = Object.values(counties).reduce((sum, county) => sum + county.well_ShutIn, 0);

      const tableData = Object.entries(counties).map(([county, values]) => ({
        ogls: values.ogls,
        oglsInProcess: values.ogls_InProcess,
        county: normalizeCountyName(county),
        grossMineralAcres: formatNumberWithCommas(values.grossMineralAcres.toFixed(2)),
        grossSurfaceAcres: formatNumberWithCommas(values.grossSurfaceAcres.toFixed(2)),
        properties: values.properties,
        row_active: values.row_active,
        row_pending: values.row_pending,
        well_active: values.well_active,
        well_InProcess: values.well_InProcess,
        well_ShutIn: values.well_ShutIn,
      }));
      // We push the state totals into the table here at the end
      tableData.push({
        state: <span style={{}}><h2>TOTAL</h2></span>,
        county: <span style={{fontWeight: 'bold'}}><b>TOTAL</b></span>,
        ogls: oglsTotal,
        oglsInProcess: oglsInProcessTotal,
        grossMineralAcres: stateTotalGMA,
        grossSurfaceAcres: stateTotalGSA,
        properties: stateTotalProperties,
        row_active: countyRowActiveTotals,
        row_pending: countyRowPending,
        well_active: countyWellActive,
        well_InProcess: countyWellInProcess,
        well_ShutIn: countyWellShutIn,
      });

      // Can push all this to exportData to use to generate a report later
      // This is relevant to a CSV output
      // exportData.push({tableData}); // Collect data for export
      return {state, tableData};
    });
  };

  return (
    <>
    {/*Use this at some later time if requested*/}
    {/*<CSVLink data={exportData.} filename={"export.csv"}>*/}
    {/*  Export CSV*/}
    {/*</CSVLink>*/}
    {/*<TableHeader tableData={exportData.flat()} />*/}

    {/* Main Table Header Component */}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{marginTop: '16px'}}>
          <p style={paragraphStyle}>
            In the early 1900's Lee Monroe, an Attorney from Topeka, KS began purchasing property in Colorado, New
            Mexico and Texas. The Oil & Gas industry had just began in earnest with the drilling of Spindle Top in
            January of 1901. Lee Monroe bought property in Reeves and Ward Counties, Texas along the Pecos river to
            develop small farming operations. He had the foresight to reserve the minerals in the event of the oil and
            gas industry moving to west Texas. In 1929 Lee Monroe signed his first Oil & Gas Lease in Block 1 of Ward
            County, he soon had his first oil well, and the story continues to this day.
          </p>
        </div>
        <div style={{marginTop: '16px', minWidth: '80%', maxWidth: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{flex: 1}}>
            <table style={{border: '1px solid #333', width: '100%'}}>
              <thead style={{backgroundColor: '#1976d2', color: 'white'}}>
              <tr>
                <th colSpan='1' style={{}}></th>
                <th colSpan='1' style={{borderLeft: '1px solid #333'}}></th>
                <th colSpan='3' style={{textAlign: 'center', border: '1px solid #333'}}>REAL ESTATE OWNERSHIP</th>
                <th colSpan='2' style={{textAlign: 'center', border: '1px solid #333'}}>OGLS</th>
                <th colSpan='2' style={{textAlign: 'center', border: '1px solid #333'}}>RIGHT OF WAYS</th>
                <th colSpan='3' style={{textAlign: 'center', border: '1px solid #333'}}>OIL AND GAS WELLS</th>
              </tr>
              <tr>
                <th scope='col' style={{
                  ...columnStyles.state, ...columnStyles.padding,
                  fontSize: '14px',
                  textAlign: 'center'
                }}>STATE
                </th>
                <th style={{
                  ...columnStyles.county, ...columnStyles.padding,
                  borderLeft: '1px solid #333',
                  fontSize: '14px',
                  textAlign: 'center'
                }}>
                  COUNTY
                </th>

                {/*REAL ESTATE OWNERSHIP*/}
                <th scope='col' style={{
                  ...columnStyles.properties, ...columnStyles.padding,
                  borderLeft: '1px solid #333',
                  fontSize: '13px',
                  textAlign: 'center'
                }}>PROPERTIES
                </th>
                <th style={{
                  ...columnStyles.grossMineralAcres, ...columnStyles.padding,
                  fontSize: '13px',
                  textAlign: 'center'
                }}>GMA
                </th>
                <th style={{
                  ...columnStyles.grossSurfaceAcres, ...columnStyles.padding,
                  fontSize: '13px',
                  textAlign: 'center'
                }}>GSA
                </th>

                {/*OGLS*/}
                <th scope='col' style={{
                  ...columnStyles.ogls, ...columnStyles.padding,
                  borderLeft: '1px solid #333',
                  fontSize: '13px',
                  textAlign: 'center'
                }}>ACTIVE
                </th>
                <th
                  style={{...columnStyles.ogls, ...columnStyles.padding, fontSize: '13px', textAlign: 'center'}}>IN
                  PROCESS
                </th>

                {/*RIGHT OF WAYS*/}
                <th scope='col' style={{
                  ...columnStyles.rowActive, ...columnStyles.padding,
                  borderLeft: '1px solid #333',
                  fontSize: '13px',
                  textAlign: 'center'
                }}> ACTIVE
                </th>
                <th style={{
                  ...columnStyles.rowPending, ...columnStyles.padding,
                  borderRight: '1px solid #333',
                  fontSize: '13px',
                  textAlign: 'center'
                }}>IN PROCESS
                </th>

                {/*OIL AND GAS WELLS*/}
                <th style={{
                  ...columnStyles.wellActive, ...columnStyles.padding,
                  fontSize: '13px',
                  textAlign: 'center'
                }}>ACTIVE
                </th>
                <th style={{
                  ...columnStyles.wellInProcess, ...columnStyles.padding,
                  fontSize: '13px',
                  textAlign: 'center'
                }}>IN PROCESS
                </th>
                <th style={{
                  ...columnStyles.wellShutIn, ...columnStyles.padding,
                  fontSize: '13px',
                  textAlign: 'center'
                }}>SHUT-IN
                </th>
              </tr>
              </thead>

              {cumulativeData(tractData).map(({state, tableData}, index) => {
                uniqueKey += 1;
                return (
                  <>
                    <tbody key={`Unique Key ${uniqueKey}`}
                           style={{borderTopWidth: '1px', borderBottomWidth: '0px', textAlign: 'center'}}>
                    {tableData.map((row, idx) => {
                      return (
                        <tr key={idx}>
                          {idx === 0 && (
                            <td rowSpan={tableData.length} style={{
                              ...columnStyles.state, ...columnStyles.padding,
                              paddingLeft: '12px',
                              paddingRight: '12px',
                              backgroundColor: '#1976d2',
                              borderRight: '1px solid #333',
                              color: 'white'
                            }}>
                              {state}
                            </td>
                          )}
                          <td style={{
                            ...columnStyles.county, ...columnStyles.fontSize, ...columnStyles.padding,
                            backgroundColor: '#1976d2',
                            textAlign: 'left',
                            color: 'white'
                          }}>{row.county}</td>
                          <td
                            style={{...columnStyles.properties, ...columnStyles.fontSize, ...columnStyles.padding}}>{row.properties}</td>
                          <td
                            style={{...columnStyles.grossMineralAcres, ...columnStyles.fontSize, ...columnStyles.padding}}>{row.grossMineralAcres}</td>
                          <td
                            style={{...columnStyles.grossSurfaceAcres, ...columnStyles.fontSize, ...columnStyles.padding}}>{row.grossSurfaceAcres}</td>
                          <td style={{
                            ...columnStyles.ogls, ...columnStyles.fontSize, ...columnStyles.padding,
                            borderLeft: '1px solid #333'
                          }}>{row.ogls}</td>
                          <td style={{
                            ...columnStyles.ogls, ...columnStyles.fontSize, ...columnStyles.padding,
                            borderRight: '1px solid #333'
                          }}>{row.oglsInProcess}</td>
                          <td
                            style={{...columnStyles.rowActive, ...columnStyles.fontSize, ...columnStyles.padding}}>{row.row_active}</td>
                          <td style={{
                            ...columnStyles.rowPending, ...columnStyles.fontSize, ...columnStyles.padding,
                            borderRight: '1px solid #333'
                          }}>{row.row_pending}</td>
                          <td
                            style={{...columnStyles.wellActive, ...columnStyles.fontSize, ...columnStyles.padding}}>{row.well_active}</td>
                          <td
                            style={{...columnStyles.wellInProcess, ...columnStyles.fontSize, ...columnStyles.padding}}>{row.well_InProcess}</td>
                          <td
                            style={{...columnStyles.wellShutIn, ...columnStyles.fontSize, ...columnStyles.padding}}>{row.well_ShutIn}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </>
                );
              })}
              <tfoot
                style={{backgroundColor: '#1976d2', border: '1px solid #333', textAlign: 'center', color: 'white'}}>
              <tr>
                <th scope='row' style={{
                  ...columnStyles.properties, ...columnStyles.fontSize, ...columnStyles.padding,
                  textAlign: 'end',
                  textWeight: 'bold'
                }}>GLOBAL
                </th>
                <th scope='row' style={{
                  ...columnStyles.properties, ...columnStyles.fontSize, ...columnStyles.padding,
                  borderRight: '1px solid #333',
                  textAlign: 'start',
                  textWeight: 'bold'
                }}>TOTAL
                </th>

                <td style={{
                  ...columnStyles.properties, ...columnStyles.fontSize, ...columnStyles.padding,
                  border: '1px solid #333'
                }}>
                  {cumulativeTotals.properties}
                </td>
                <td style={{...columnStyles.grossMineralAcres, ...columnStyles.fontSize, ...columnStyles.padding}}>
                  {formatNumberWithCommas(cumulativeTotals.grossMineralAcres.toFixed(2))}
                </td>
                <td style={{...columnStyles.grossSurfaceAcres, ...columnStyles.fontSize, ...columnStyles.padding}}>
                  {formatNumberWithCommas(cumulativeTotals.grossSurfaceAcres.toFixed(2))}
                </td>
                <td style={{
                  ...columnStyles.ogls, ...columnStyles.fontSize, ...columnStyles.padding,
                  borderLeft: '1px solid #333'
                }}>
                  {cumulativeTotals.ogls}
                </td>
                <td style={{
                  ...columnStyles.ogls, ...columnStyles.fontSize, ...columnStyles.padding,
                  borderRight: '1px solid #333'
                }}>
                  {cumulativeTotals.ogls_InProcess}
                </td>
                <td style={{...columnStyles.rowActive, ...columnStyles.fontSize, ...columnStyles.padding}}>
                  {cumulativeTotals.row_active}
                </td>
                <td style={{
                  ...columnStyles.rowPending, ...columnStyles.fontSize, ...columnStyles.padding,
                  borderRight: '1px solid #333'
                }}>
                  {cumulativeTotals.row_pending}
                </td>
                <td style={{...columnStyles.wellActive, ...columnStyles.fontSize, ...columnStyles.padding}}>
                  {cumulativeTotals.well_active}
                </td>
                <td style={{...columnStyles.wellInProcess, ...columnStyles.fontSize, ...columnStyles.padding}}>
                  {cumulativeTotals.well_InProcess}
                </td>
                <td style={{...columnStyles.wellShutIn, ...columnStyles.fontSize, ...columnStyles.padding}}>
                  {cumulativeTotals.well_ShutIn}
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
          <div style={{
            background: 'linear-gradient(360deg, #fff 0%, #1976d2 100%)',
            borderTop: '1px solid #333',
            fontWeight: 'bold',
            textAlign: 'end',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'flex-start',
            minHeight: '560px',
            paddingLeft: '6px',
            paddingRight: '6px',
          }}>
            <span style={{marginTop: '1px'}}>{moment().format("h:mm A")}</span>
            <span style={{marginTop: '6px'}}>{moment().format("dddd MMMM Do")}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;