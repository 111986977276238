import React, { useState } from 'react';
import Link from 'react-router-dom/Link';
import MaterialTable, { MTableToolbar } from 'material-table';
import queryString from 'query-string';
import {Toolbar, Button, TextField, InputAdornment } from '@material-ui/core';

function getPageNumberFromQueryParams({ page }) {
  if (!page) {
    return 0;
  }

  if (isNaN(page)) {
    return 0;
  }

  return Number(page);
}

function replaceHistoryWithPage(pageNum, { history }) {
  history.replace(`/Operators?page=${pageNum}`);
}

const OperatorTable = ({ data, history }) => {
  const [pageNumber, setPageNumber] = useState(getPageNumberFromQueryParams(queryString.parse(window.location.search)));
  const [searchQuery, setSearchQuery] = useState('');
  const columns = [
    {
      title: 'CONTACT NAME',
      field: 'name',
      render: (rowData) => (
        <Link
          to={`/Operators/${rowData.id}`}
          target='_blank'
          onClick={() => replaceHistoryWithPage(pageNumber, { history })}
        >
          {rowData.name}
        </Link>
      ),
    },
    {
      title: 'TYPE',
      field: 'type',
    },
    {
      title: 'COMPANY WEBSITE',
      field: 'companyWebsite',
      render: (rowData) => (
        <Link to={`${rowData.companyWebsite}`} target='_blank' rel='noopener noreferrer'>
          {rowData.companyWebsite}
        </Link>
      ),
    },
    {
      title: 'OPERATOR NUMBER',
      field: 'operatorNumber',
    },
    {
      title: 'MAIN PHONE',
      field: 'phone',
      render: (rowData) => (rowData.phone ? rowData.phone : 'N/A'),
    },
    {
      title: 'MES #',
      field: 'mesAccountNumber',
      render: (rowData) => (rowData.mesAccountNumber ? rowData.mesAccountNumber : 'N/A'),
    },
    {
      title: 'MPI #',
      field: 'mpiAccountNumber',
      render: (rowData) => (rowData.mpiAccountNumber ? rowData.mpiAccountNumber : 'N/A'),
    }
  ];

  return (
    <div style={{display: 'flex', justifyContent: 'center', overflowX: 'hidden', padding: '0 16px'}}>
      <div style={{width: '100%', maxWidth: '1200px', marginTop: '16px'}}>
        <MaterialTable
          data={data}
          columns={columns}
          title={''}
          onChangePage={(page) => setPageNumber(page)}
          options={{
            initialPage: pageNumber,
            headerStyle: {
              backgroundColor: '#1976d2',
              color: '#fff',
              whiteSpace: 'nowrap',
              width: '100%',
              position: 'sticky',
              top: 0,
            },
            maxBodyHeight: '770px',
            pageSize: data.length,
            /*exportButton: false,*/
            selection: false,
            search: true,
            searchFieldStyle: {
              marginLeft: '-24px',
              border: 'none',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              backgroundColor: '#fff',
              color: '#000',
            },
            searchFieldAlignment: 'left',
            toolbarButtonAlignment: 'left',
          }}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: '#1976d2',
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                  paddingBottom: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MTableToolbar {...props} />
                <button
                  onClick={() => history.push('/addOperator')}
                  style={{
                    marginRight: '16px',
                    border: 'none',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    color: '#000',
                  }}
                >
                  Add Contact
                </button>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default OperatorTable;