import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_OPERATOR,
  EDIT_OPERATOR,
  DefaultOperator,
  DELETE_OPERATOR,
} from '../../components/operators/OperatorQueries';
import LoadingView from '../../components/LoadingView';
import OperatorDetailPage from './OperatorDetailPage';
import { ADD_CONTACT, DELETE_CONTACT, EDIT_CONTACT } from '../../components/contacts/ContactsQueries';
import gql from 'graphql-tag';

const operatorContactsFragment = gql`
  fragment operatorContactsFragment on Operator {
    contacts {
      id
      contactName
      position
      directLine
      cell
      email
    }
  }
`;

const GetOperatorFromQuery = (queryResult) => {
  if (!Array.isArray(queryResult) || queryResult.length === 0) {
    return false;
  }

  return queryResult[0];
};

const EditOperator = ({ match, history }) => {
  const OperatorQuery = useQuery(GET_OPERATOR, {
    variables: {
      id: match.params.operatorID,
    },
  });

  const [EditOperatorQuery] = useMutation(EDIT_OPERATOR, { awaitRefetchQueries: true });

  const [addNewContact] = useMutation(ADD_CONTACT, { awaitRefectQueries: true });

  const [deleteContact] = useMutation(DELETE_CONTACT, { awaitRefetchQueries: true });

  const [editContact] = useMutation(EDIT_CONTACT, { awaitRefetchQueries: true });

  const [deleteOperator] = useMutation(DELETE_OPERATOR, { awaitRefetchQueries: true });

  const onSubmit = (newOperatorFormValues) => {
    return EditOperatorQuery({
      variables: newOperatorFormValues,
      update: (store, { data: { UpdateOperator } }) => {},
    });
  };

  // createNewContact Returns the contact created
  const addContact = (newContactValues) => {
    return addNewContact({
      variables: newContactValues,
      update: (store, { data: { createNewContact } }) => {
        try {
          const oldContacts = store.readFragment({
            id: newContactValues.operatorID,
            fragment: operatorContactsFragment,
          });

          const updatedContacts = [...oldContacts.contacts, createNewContact];

          store.writeFragment({
            id: newContactValues.operatorID,
            fragment: operatorContactsFragment,
            data: {
              __typename: 'Operator',
              contacts: updatedContacts,
            },
          });
        } catch (e) {
          console.log(e);
          console.error('Could not write contact fragment on operator!');
        }
      },
    });
  };

  const removeContact = (contactID) => {
    return deleteContact({
      variables: {
        id: contactID,
      },
      update: (store, { data: { deleteContact } }) => {
        try {
          const oldContacts = store.readFragment({ id: match.params.operatorID, fragment: operatorContactsFragment });

          const updatedContacts = oldContacts.contacts.filter((contact) => contact.id !== contactID);

          store.data.delete(contactID);

          store.writeFragment({
            id: match.params.operatorID,
            fragment: operatorContactsFragment,
            data: {
              __typename: 'Operator',
              contacts: updatedContacts,
            },
          });
        } catch (e) {
          console.error('Could not delete contact on operator fragment');
        }
      },
    });
  };

  const updateContact = (updatedContactInfo) => {
    return editContact({
      variables: updatedContactInfo,
      update: (store, { data: { UpdateContact } }) => {},
    });
  };

  if (OperatorQuery.error || EditOperatorQuery.error) {
    return <h1>{OperatorQuery.error.toString()}</h1>;
  } else if (OperatorQuery.loading || EditOperatorQuery.loading) {
    return <LoadingView />;
  }

  const Operator = GetOperatorFromQuery(OperatorQuery.data.Operator) || DefaultOperator;

  return (
    <div className="container-fluid mt-3 overflow-hidden">
      <OperatorDetailPage
        Operator={Operator}
        onSubmit={onSubmit}
        addContact={addContact}
        removeContact={removeContact}
        updateContact={updateContact}
        deleteOperator={deleteOperator}
        history={history}
      />
    </div>
  );
};

export default EditOperator;
