import React, {useState} from 'react';
import {Formik, Form, Field, FieldArray} from 'formik';
import {TextField} from 'formik-material-ui';
import {makeStyles} from '@material-ui/styles';
import {useSnackbar} from 'notistack';
import Auth from '../../util/Auth';
import {Toolbar as MaterialToolbar, Button, MenuItem, TextField as MaterialUITextField} from '@material-ui/core';
import MaterialTable from 'material-table';
import operatorSchema from '../../components/operators/OperatorSchema';
import DeleteOperatorModal from './DeleteOperatorModal';
import {Prompt} from 'react-router';
import moment from 'moment';
import Select from 'react-select';
import PERMISSIONS_LIST from '../../util/RolesEnum';

const hasPermissions = Auth.hasRole(PERMISSIONS_LIST.WRITE.WRITE_OPERATORS);

const OperatorDetailPage = ({
                              Operator,
                              onSubmit,
                              addContact,
                              removeContact,
                              updateContact,
                              deleteOperator,
                              history,
                              ...props
                            }) => {

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const initialValues = {
    ...Operator,
    name: Operator.name || '',
    type: Operator.type || '',
    address1: Operator.address1 || '',
    operatorNumber: Operator.operatorNumber || '',
    city: Operator.city || '',
    mesAccountNumber: Operator.mesAccountNumber || '',
    state: Operator.state || '',
    mpiAccountNumber: Operator.mpiAccountNumber || '',
    zip: Operator.zip || '',
    sroAccountNumber: Operator.sroAccountNumber || '',
    phone: Operator.phone || '',
    taxPayerID: Operator.taxPayerID || '',
    companyWebsite: Operator.companyWebsite || '',
    notes: Operator.notes || '',
    contacts: Operator.contacts || [],
    initialStatus: Operator.initialStatus || '',
    status: Operator.status || '',
  };

  const current = [{type: 'Yes'}, {type: 'No'}];

  const CustomToolbar = ({ onAddNewRow, onRowUpdate, onRowDelete, selectedRowData, addContact, updateContact, removeContact }) => {
    return (
      <MaterialToolbar
        style={{
          backgroundColor: '#1976d2',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          paddingTop: '32px',
          paddingBottom: '16px'
        }}
      >
        <div style={{ flex: 'auto' }}></div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <button
            type="button"
            onClick={onAddNewRow}
            style={{
              border: 'none',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              backgroundColor: 'rgb(245 245 245)',
              color: '#1976d2',
            }}
          >
            Add Individual
          </button>
          <button
            type="button"
            onClick={() => {
              if (selectedRowData) {
                handleRowUpdate(selectedRowData, selectedRowData, updateContact);
              }
            }}
            style={{
              border: 'none',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              backgroundColor: 'rgb(245 245 245)',
              color: 'black'
            }}
          >
            Edit
          </button>
          <button
            type="button"
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this contact?')) {
              onRowDelete(selectedRowData)
              }
            }}
            disabled={!selectedRowData}
            style={{
              border: 'none',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              backgroundColor: 'rgb(245 245 245)',
              color: 'red'
            }}
          >
            Delete
          </button>
        </div>
      </MaterialToolbar>
    );
  };

  const handleAddNewRow = (unshift, addContact) => {
    setIsEditable(true);
    const newData = {
      operatorID: Operator.id,
      contactName: '',
      position: '',
      directLine: '',
      cell: '',
      email: '',
      current: 'No',
      date: '',
      notes: '',
    };

    addContact(newData).then(({ data: { createNewContact } }) => {
      newData.id = createNewContact.id;
      unshift(newData);
    }).catch(error => {
      console.error('Error in addContact:', error);
    });
  };

  const handleRowUpdate = (newData, oldData, updateContact, props) => {
    setIsEditable(true);

    updateContact(newData).then(() => {
      const dataUpdate = [...props.values.contacts];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      props.setFieldValue('contacts', dataUpdate); // Update Formik values
      setSelectedRowData(null); // Clear selected row data
      window.location.reload(); // Refresh the page
    }).catch(error => {
      console.error('Error in updateContact:', error);
    });
  };

  const handleRowDelete = (oldData, remove, removeContact) => {
    const id = oldData.tableData.id;
    removeContact(oldData.id).then(() => remove(id)).catch(error => {
      console.error('Error in removeContact:', error);
    });
  };

  return (
    <>
    <div style={{display: 'flex', justifyContent: 'center', overflowX: 'hidden', padding: '0 16px'}}>
      <div style={{width: '100%', maxWidth: '1200px', marginTop: '16px'}}>
        <Formik
          initialValues={initialValues}
          validationSchema={operatorSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            return onSubmit(values).then(
              (value) => {
                enqueueSnackbar('Form Successfully Edited', {variant: 'success'});
                actions.setSubmitting(false);
                closeSnackbar();
              },
              (error) => {
                enqueueSnackbar('Error Submitting Form', {variant: 'error'});
                actions.setSubmitting(false);
                closeSnackbar();
              }
            );
          }}
        >
          {(props) => {
            return (
              <>
                <Prompt
                  when={props.dirty && props.submitCount < 1}
                  message='You have not submitted the form, are you sure you want to leave?'
                />
                <Form>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#1976d2',
                      borderTopLeftRadius: '6px',
                      borderTopRightRadius: '6px',
                      paddingTop: '32px',
                      paddingBottom: '32px'
                    }}
                  >
                    <div style={{flex: 'auto'}}></div>
                    <div style={{marginLeft: '64px', flex: 'none', marginTop: '0px'}}>
                      <div style={{display: 'flex', gap: '8px', marginRight: '16px'}}>
                        <button
                          type="button"
                          onClick={(event) => props.handleSubmit()}
                          disabled={!props.isValid}
                          style={{
                            border: 'none',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            borderRadius: '4px',
                            backgroundColor: 'rgb(245 245 245)',
                            color: '#1976d2',
                          }}
                        >
                          Update Contact
                        </button>
                        <button
                          type="button"
                          onClick={() => setModalOpen(true)}
                          style={{
                            border: 'none',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            borderRadius: '4px',
                            backgroundColor: 'rgb(245 245 245)',
                            color: 'red'
                          }}
                        >
                          Delete Contact
                        </button>
                        <DeleteOperatorModal
                          open={modalOpen}
                          handleClose={() => setModalOpen(false)}
                          handleAgree={() => {
                            deleteOperator({
                              variables: {
                                id: props.values.id,
                              },
                              update: (store, {data: {DeleteOperator}}) => {
                                store.data.delete(DeleteOperator.id);
                              },
                            })
                              .then(({data}) => {
                                enqueueSnackbar('Successfully deleted Contact', {variant: 'success'});
                                history.push('/Operators');
                              })
                              .catch((err) => {
                                console.error(err);
                                enqueueSnackbar('Could not delete Contact', {variant: 'error'});
                              });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <table style={{minWidth: '100%', marginTop: '16px'}}>
                    <tbody>
                    <tr>
                      <th style={{borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Company Name:
                        </label>
                        <Field
                          name="name"
                          placeholder="Contact Name"
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </th>
                      <td style={{borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Type Dropdown:
                        </label>
                        <Field
                          name="type"
                          select
                          component={TextField}
                        >
                          <MenuItem value={'Attorney'}>Attorney</MenuItem>
                          <MenuItem value={'County Gov'}>County Gov</MenuItem>
                          <MenuItem value={'Education'}>Education</MenuItem>
                          <MenuItem value={'Family Contact'}>Family Contact</MenuItem>
                          <MenuItem value={'Family Office'}>Family Office</MenuItem>
                          <MenuItem value={'Lessee'}>Lessee</MenuItem>
                          <MenuItem value={'O&G'}>O&G</MenuItem>
                          <MenuItem value={'Office/Business'}>Office/Business</MenuItem>
                          <MenuItem value={'Regulatory'}>Regulatory</MenuItem>
                          <MenuItem value={'ROW'}>ROW</MenuItem>
                          <MenuItem value={'Surface Contractor'}>Surface Contractor</MenuItem>
                        </Field>
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Address:
                        </label>
                        <Field
                          name='address1'
                          placeholder='Address'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Operator #:
                        </label>
                        <Field
                          name='operatorNumber'
                          placeholder='Operator #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          City:
                        </label>
                        <Field
                          name='city'
                          placeholder='City'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          MES Account #:
                        </label>
                        <Field
                          name='mesAccountNumber'
                          placeholder='MES Account #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          State:
                        </label>
                        <Field
                          name='state'
                          placeholder='State'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          MPI Account #:
                        </label>
                        <Field
                          name='mpiAccountNumber'
                          placeholder='MPI Account #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Zip Code:
                        </label>
                        <Field
                          name='zip'
                          placeholder='Zip Code'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          SRO Account #:
                        </label>
                        <Field
                          name='sroAccountNumber'
                          placeholder='SRO Account #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Phone #:
                        </label>
                        <Field
                          name='phone'
                          placeholder='Phone #'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Tax Payrer ID-CONG:
                        </label>
                        <Field
                          name='taxPayerID'
                          placeholder='Tax Payer ID-CONG'
                          component={TextField}
                          style={{width: '270px'}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{borderTop: 'none', borderBottom: 'none'}}>
                        <label style={{
                          width: '35%',
                          marginRight: '16px',
                          color: 'rgb(163 163 163)',
                          fontSize: '14px'
                        }}>
                          Website:
                        </label>
                        <Field
                          name='companyWebsite'
                          placeholder='Company Website'
                          component={TextField}
                          style={{minWidth: '270px'}}
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div style={{marginTop: '6px'}}>
                    <label style={{
                      marginRight: '16px',
                      color: 'rgb(163 163 163)',
                      fontSize: '14px'
                    }}>
                      Notes:
                    </label>
                    <Field
                      name='notes'
                      placeholder='Notes'
                      component={TextField}
                      multiline={true}
                      rows={4}
                      variant='filled'
                      fullWidth
                    />
                  </div>
                  <FieldArray name="contacts">
                    {({unshift, remove, insert}) => {
                      const columns = [
                        {
                          title: 'Current Y/N',
                          field: 'current',
                          editComponent: ({onChange}) => (
                            <Select
                              options={current}
                              getOptionLabel={(option) => option.type}
                              getOptionValue={(option) => option.type}
                              onChange={({type}) => onChange(type)}
                            />
                          ),
                        },
                        {
                          title: 'Name',
                          field: 'contactName',
                        },
                        {
                          title: 'Position',
                          field: 'position',
                        },
                        {
                          title: 'Direct Line',
                          field: 'directLine',
                        },
                        {
                          title: 'E-Mail',
                          field: 'email',
                        },
                        {
                          title: 'Cell Number',
                          field: 'cell',
                        },
                        {
                          title: 'Date Entered',
                          field: 'date',
                          render: (rowData) =>
                            !rowData.date || rowData.date === 'N/A' ? 'N/A' : moment(rowData.date).format('MM/DD/YYYY'),
                          editComponent: (props) => (
                            <MaterialUITextField type="date" onChange={(e) => props.onChange(e.target.value)}/>
                          ),
                          type: 'date',
                        },
                        {
                          title: 'Notes',
                          field: 'notes',
                        },
                      ];
                      return (
                        <div style={{marginTop: '32px'}}>
                          <MaterialTable
                            columns={columns}
                            data={props.values.contacts.map(o => ({...o}))}
                            title={'Contact'}
                            options={{
                              headerStyle: {
                                backgroundColor: '#1976d2',
                                color: '#FFF',
                                whiteSpace: 'nowrap',
                                width: '100%',
                              },
                              rowStyle: (rowData) => ({
                                ...(selectedRow && selectedRow.tableData.id === rowData.tableData.id && {
                                  backgroundColor: 'rgb(245 245 245)',
                                }),
                              }),
                              pageSize: 5,
                              exportButton: false,
                              toolbarButtonAlignment: 'left',
                              searchFieldAlignment: 'left',
                            }}
                            editable={isEditable ? {
                              onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, updateContact, props).then(() => {
                                  resolve();
                                }).catch(error => {
                                  console.error('Error in updateContact:', error);
                                  reject();
                                });
                              }),
                            } : undefined}
                            onRowClick={(evt, selectedRow) => {
                              setSelectedRow(selectedRow);
                              setSelectedRowData(selectedRow);
                            }}
                            components={{
                              Toolbar: (props) => (
                                <CustomToolbar
                                  onAddNewRow={() => handleAddNewRow(unshift, addContact)}
                                  onRowUpdate={(data) => handleRowUpdate(data, remove, insert, updateContact)}
                                  onRowDelete={(data) => handleRowDelete(data, remove, removeContact)}
                                  selectedRowData={selectedRowData}
                                  addContact={addContact}
                                  updateContact={updateContact}
                                  removeContact={removeContact}
                                />
                              ),
                            }}
                          />
                        </div>
                      )
                        ;
                    }}
                  </FieldArray>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
    </>
  );
};

export default OperatorDetailPage;